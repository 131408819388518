// Variáveis globais
$primary-color: #051532;
$overlay-color: rgba(0, 0, 0, 0.6);
$gray-color: #ccc;
$selected-color: $primary-color;

// Overlay do modal
.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: $overlay-color;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
}
.modalOverlay_ {
  display: none;
}

// Conteúdo do modal
.modalContent {
  background: var(--white);
  padding: 2rem;
  width: 35rem;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  position: relative;
}
.closeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: var(--gray-100);
  color: var(--gray-500);
  font-size: 1.5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  padding-bottom: 2px;
}

.title,
.heading {
  font-size: 1.6rem;
  font-weight: bold;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--primary);
}

.description {
  font-size: 0.9rem;
  color: var(--gray-500);
  margin-bottom: 1.5rem;
}

.subtitle {
  font-size: 1.2rem;
  font-weight: 600;
  margin-top: 1rem;
  margin-bottom: 1rem;
  color: var(--primary);
  text-align: left;
}

// Formulário comum
.form {
  display: flex;
  flex-direction: column;
  align-items: center;

  .errorMessage {
    color: #ff3333;
    font-size: 14px;
    margin-top: 5px;
    margin-left: 10px;
  }

  .reasonInput {
    position: relative;
    list-style: none;
    display: flex;
    align-items: center;
    flex-wrap: no-wrap;
    width: 100%;
    background-color: var(--gray-5);
    padding: 5px 12px;
    border-radius: 14px;
    border: 1px solid var(--gray-300);
    gap: 5px;

    input {
      width: 100%;
      border: none;
      background: none;
      padding: 8px;
      color: var(--gray-500);

      &:focus {
        outline: none;
      }
    }
  }

  .listRefuses {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: no-wrap;
    width: 100%;
    background-color: var(--gray-5);
    padding: 10px;
    padding-top: 0px;
    border-radius: 0 0 14px 14px;
    border: 1px solid var(--gray-300);
    border-top: none;
    gap: 5px;

    span {
      font-size: 0.7rem;
      font-weight: 600;
      color: var(--primary);
      margin: 10px 0 0 20px;
      align-self: flex-start;
    }

    ul {
      list-style: none;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-height: 7.4rem;
      overflow-y: auto;

      li {
        width: 92%;
        display: flex;
        align-items: center;
        padding: 10px;
        padding-left: 14px;
        border-radius: 10px;
        gap: 5px;
        cursor: pointer;
        font-size: small;
        font-weight: 400;
        color: var(--primary);

        &:hover {
          background-color: var(--gray-100);
        }
      }
    }
  }

  .wrapper_buttons {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 1rem;

    .cancelButton {
      width: 50%;
      background-color: var(--white);
      font-size: 1rem;
      color: var(--gray-500);
      border: 1px solid var(--gray-100);
      padding: 1rem;
      border-radius: 12px;
      cursor: pointer;
      transition: ease 0.3s;

      &:hover {
        background: var(--gray-50);
      }
    }

    .submitButton {
      width: 50%;
      background: var(--primary);
      font-size: 1rem;
      color: #fff;
      border: none;
      padding: 1rem;
      border-radius: 12px;
      cursor: pointer;
      transition: ease 0.3s;

      &:hover {
        background: darken($primary-color, 10%);
      }
    }
  }
}
