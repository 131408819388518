.container {
  border: 1px solid var(--gray-50);
  width: 100%;
  height: 10rem;
  padding: 1.5rem;
  display: grid;
  grid-template-columns: auto 10rem;
  border-radius: 1.25rem;
  background: var(--white);
}
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  z-index: 0;

  ul {
    width: 100%;
    display: flex;
    gap: 0.5rem;
    justify-content: space-between;
    @media screen and (max-width: 420px) {
      margin-top: 3rem;
      align-items: center;
    }
  }
}
.icon {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
}
.wrapper_balance {
  height: 20rem;
  width: 100%;
  display: flex;
  align-items: center;
}

.balance {
  color: var(--gray-800);
  font-size: 2rem;
  font-weight: 600;

  @media screen and (max-width: 420px) {
    font-size: 16px;
    font-weight: bold;
  }
}
.balance_ {
  color: var(--gray-500);
  font-size: 2rem;
  font-weight: 600;

  @media screen and (max-width: 420px) {
    font-size: 16px;
    font-weight: bold;
  }
}

.name {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.clients {
  font-size: 0.75rem;
  color: var(--gray-500);
  font-size: 1rem;
  font-weight: 500;
}

.loading {
  span {
    animation: dots 0.6s cubic-bezier(0.6, 0.1, 1, 0.4);
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  span:nth-child(0) {
    animation-delay: 0.1s;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.3s;
  }

  span:nth-child(4) {
    animation-delay: 0.4s;
  }

  @keyframes dots {
    from {
      transform: translate3D(0, 0, 0);
    }
    to {
      transform: translate3D(0, 10px, 0);
    }
  }

  span {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: var(--orange-text);
    font-weight: bold;
  }

  margin: 0 auto;
  text-align: center;
}
.wrapper_footer {
  display: flex;
  gap: 0.5rem;
  strong {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--red-text);
  }
  span {
    font-size: 0.9rem;
    font-weight: 600rem;
    color: var(--gray-300);
  }
}
